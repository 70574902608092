import { TagsService } from './services/tags/tags.service';
import { PwaService } from './services/pwa/pwa.service';
import { Component, HostListener, OnInit } from '@angular/core';
import {
  Platform,
  AlertController,
} from '@ionic/angular';

import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';

//Services
import { RemoteConfigService } from './services/remote-config/remote-config.service';

import { ValidateVersionService } from './services/validateVersion/validate-version.service';
import { RouterService } from './services/router/router.service';
import { AuthService } from './services/auth/auth.service';
import { LanguagesService } from './services/languagesService/languages.service';
import { FcmService } from './services/fcm/fcm.service';
import { AuthGnxService } from 'src/app/services/authGnx/auth-gnx.service';
import { UpToDateBuildService } from './services/build-detail/up-to-date.service';
import { getTagsConfigClicked } from './services/tags/tagsConfig';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  settingsDidMount: boolean = false;
  public alertButtons = [
    {
      text: 'Actualizar',
      role: 'confirm',
      handler: () => {
        // this.StorageService.removeAll();
        window.location.reload();
      },
    },
  ];


  @HostListener('click', ['$event.target']) onClick(e:HTMLElement) {
    const tagEvent:any = e.getAttribute('tag_event_key');
    // console.debug(e)
    if (!tagEvent) return;
    this.tagsService.setTagFromEvent(tagEvent);
  }

  constructor(
    private platform: Platform,
    private remoteConfigService: RemoteConfigService,
    private validateVersion: ValidateVersionService,
    private firebaseDynamicLinks: FirebaseDynamicLinks,
    private routerService: RouterService,
    private authService: AuthService,
    private languageService: LanguagesService,
    private fcmService: FcmService,
    private authGnx: AuthGnxService,
    private PwaService: PwaService,
    private upToDateService: UpToDateBuildService,
    private tagsService: TagsService,
    public  alertController: AlertController,
  ) {
    this.initializeApp();
    this.authService.setApiKeyByPlatform();

    const updateApp$ = this.upToDateService.buildIsUpToDate.subscribe(
      async (buildIsUpToDate) => {
        if (!buildIsUpToDate) return;

        const alert = await this.alertController.create({
          header: 'Actualizacion',
          subHeader: 'La aplicacion tiene una actualizacion importante',
          buttons: this.alertButtons,
          backdropDismiss: false,
        });
        await alert.present();
        updateApp$.unsubscribe();
      }
    );
  }

  ngOnInit() {
    const language = navigator.languages.includes('-');
    if (!this.languageService.loadedLanguage) {
      this.languageService.loadLanguage('en');
    }
    this.PwaService.initPwaPrompt();
  }
  async initializeApp() {
    // this.authService.getParamsAuth("https://gnx-mvp.firebaseapp.com/auth-confirmation?apiKey=AIzaSyCzc4umKlI4KtSpgl41U0axBh40Mf-QBYM&mode=verifyEmail&oobCode=1A008dXFVM84vesFyaqwbNpr0A_9Zq1DBs9a1uyRCJAAAAF2gh9Vsw&continueUrl=https://gnx-mvp.firebaseapp.com/&lang=es-419")
    this.validateVersion.validate();
    //  await this.settignsService.initSettings();
    this.listenForDynamicLinks();

    this.platform.ready().then(() => {
      //this.analyticsService.initAnalytics();
      this.fcmService.initPush();
    });

    this.settingsDidMount = true;
    this.remoteConfigService.initRemoteConfig();

    if (window.location.pathname != '/login') {
      this.authGnx
        .confirmSignIn(window.location.href)
        .then(async (auth) => {
          if (auth) {
            console.log(
              'entro al avalidacion del home',
              auth,
              auth.user.tokenReresh
            );
            await this.authGnx.loginUserWithLink();
            //this.navCtrl.navigateRoot("app/home");
          }
        })
        .catch((err) => {
          console.error('error app XXXXX', err);
        });
    }
  }

  listenForDynamicLinks() {
    this.firebaseDynamicLinks.onDynamicLink().subscribe(
      (res: any) => {
        this.authService.getParamsAuth(res.deepLink);
        this.routerService.redirect('auth-confirmation');
        //  window.location.href = res.deepLink;k
      },
      (error: any) => console.log(error)
    );
  }

  /**
   * Deshabilita el menu de notificaciones teniendo en cuenta la rutas
   * @return { Boolean}
   * @memberof AppComponent
   */
  getPathDisableMenuNotifications() {
    const paths = [
      'login',
      'register',
      'recover-account',
      'live-chat',
      'register-info-extra',
    ]; // para inhabilitar el menu en una ruta solo añada la ruta en este array
    let disable = false;
    paths.forEach((data) => {
      if ((this.routerService.getCurrentUrl() as string)?.includes(data)) {
        disable = true;
      }
    });
    return disable;
  }
}
